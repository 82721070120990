import React from 'react';
import student_image from "../assets/stats-background.png";


const Count = () => {
    return (
        <div className='bg-[#EA7052] my-12 lg:my-52 rounded-[0px] lg:rounded-[50px] py-14 lg:py-38' style={{
            backgroundImage: `linear-gradient(to bottom, rgba(14, 36, 74, 0.8), rgba(14, 36, 74, 0.4)), url(${student_image})`,
            backgroundSize: "cover",
            height: 300
        }}>
            <div className='grid place-items-center'>
                <div className='w-[90%] lg:w-[70%] space-y-2 lg:space-y-4'>
                    <h1 className='text-center text-white text-3xl lg:text-5xl font-bold'>The <span className='text-[#EA7052] text-3xl lg:text-5xl font-bold'>revolutionary app</span> everyone is </h1>
                    <h1 className='text-center text-white text-3xl lg:text-5xl font-bold'>talking about</h1>
                </div>
            </div>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-8 lg:pt-12'>
                <div>
                    <h1 className='text-white text-4xl lg:text-5xl font-bold text-center'>5m+</h1>
                    <h1 className='text-[16px] lg:text-[18px] text-center text-white'>App Downloads</h1>
                </div>
                <div>
                    <h1 className='text-white text-4xl lg:text-5xl font-bold text-center'>5m+</h1>
                    <h1 className='text-[16px] lg:text-[18px] text-center text-white'>App Downloads</h1>
                </div>
                <div>
                    <h1 className='text-white text-4xl lg:text-5xl font-bold text-center'>5m+</h1>
                    <h1 className='text-[16px] lg:text-[18px] text-center text-white'>App Downloads</h1>
                </div>
            </div>
        </div>
    )
}

export default Count;