import React from 'react'
import QuizCards from './QuizCards'

const Quiz = () => {
    return (
        <div className=' my-16 lg:my-36'>
            <div className=' grid place-items-center'>
                <div className=' w-full lg:w-[55%] space-y-7'>
                    <h1 className=' text-center text-3xl  lg:text-5xl font-bold text-[#3F3A64]'>Dive into our vast video library, quizzes, and exams</h1>
                    <p className='  text-center lg:text-[18px] text-[16px] text-[#20AD96]'>Enjoy the flexibility of our subscription options; choose between a monthly, annual, or lifetime commitment to learning.</p>
                </div>
            </div>
            <div className=' grid lg:grid-cols-3 grid-cols-1 gap-5 pt-12 px-3'>
                <QuizCards />
                <QuizCards />
                <QuizCards />
            </div>
        </div>
    )
}

export default Quiz