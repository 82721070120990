import React from 'react'

const ClassCards = ({ image, title, description }) => {
    return (
        <div className=' rounded-[16px] bg-[white] shadow-md px-7 py-7'>
            <img className=' rounded-[12px]' src={image} style={{ width: "100%", maxHeight: 300, maxWidth: "100%" }} />
            <div className=' space-y-5 pt-5'>
                <h1 className=' text-xl font-semibold text-[#3F3A64]'>{title}</h1>
                <p className='text-[15px] text-[#20AD96]'>{description}</p>
            </div>

        </div>
    )
}

export default ClassCards