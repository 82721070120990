import React, { useRef } from 'react';
import { Header } from '../Components/Header';
import Footer from '../Components/Footer';
import ServicesCards from '../Components/ServicesCards';
import black from "../assets/student1rm.png";
import badge from "../assets/badge.png";
import customer from "../assets/customer.jfif";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Forms from '../Components/Forms';
import Banner from '../Components/Banner';
import StudentCommentContainer from '../Components/StudentCommentContainer';
import StudentImage from '../Components/StudentImage';
import Quiz from '../Components/Quiz';
import Devices from '../Components/Devices';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Count from '../Components/Count';
import LiveLesson from '../Components/LiveLesson';
import MobileHeader from '../Components/MobileComponents/MobileHeader';
import DownloadOnline from '../Components/DownloadOnline';




const HomePage = () => {
  const targetRef = useRef(null);
  return (
    <>
      <Header targetRef={targetRef} />
      <MobileHeader />
      <Banner />
      <div className=' bg-[#F2F2FB] px-0 lg:px-44'>
        {/* <div ref={targetRef}> */}
          <DownloadOnline targetRef={targetRef} />
        {/* </div> */}
        {/* <div className=' grid grid-cols-10 py-16'>
          <div className=' col-span-6'>
            <div>
              <div className=' grid grid-cols-1 lg:grid-cols-2 gap-5'>
                <ServicesCards color="#F9AD6D" />
                <ServicesCards color="#68BC98" />

              </div>
              <div className=' grid grid-cols-1 lg:grid-cols-2 gap-5 pt-14'>
                <ServicesCards color="#EA7052" />
                <ServicesCards color="#7B7FDA" />
              </div>
            </div>
          </div>
          <div className=' col-span-4'>
            <div className=''>
              <img className=' rounded-3xl' style={{ maxWidth: "100%", height: 500 }} src={black} />
            </div>
          </div>
        </div> */}
        <Forms />
        <div className=' bg-white px-4 sm:px-14 py-16 rounded-[50px] my-36'>
          <h1 className=' font-bold text-3xl sm:text-5xl text-center text-[#3F3A64]'>Schools that trust us</h1>
          <div className=' grid grid-cols-2 sm:grid-cols-4 py-8 gap-4'>
            <div>
              <img className='w-full h-auto max-h-48' src={badge} />
            </div>
            <div>
              <img className='w-full h-auto max-h-48' src={badge} />
            </div>
            <div>
              <img className='w-full h-auto max-h-48' src={badge} />
            </div>
            <div>
              <img className='w-full h-auto max-h-48' src={badge} />
            </div>
          </div>
        </div>

        <StudentCommentContainer />
        <StudentImage />
        <Quiz />
        <LiveLesson />
        <Count />
        <Devices />

        <div>

        </div>

        <div className='bg-[#20AD96] px-4 sm:px-14 py-16 rounded-[0px] lg:rounded-[50px] lg:my-36 mb-10'>
          <h1 className='font-bold text-3xl sm:text-5xl text-center text-white'>Contact Us</h1>
          <p className='text-center text-lg text-white mb-8'>We'd love to hear from you! Please fill out the form below.</p>
          <form className='flex flex-col items-center'>
            <input type="text" placeholder="Your Name" className='border rounded-md p-2 mb-4 w-full max-w-md' required />
            <input type="email" placeholder="Your Email" className='border rounded-md p-2 mb-4 w-full max-w-md' required />
            <textarea placeholder="Your Message" className='border rounded-md p-2 mb-4 w-full max-w-md' rows="4" required></textarea>
            <button type="submit" className='bg-[#3F3A64] text-[#ffffff] rounded-md p-3 w-full max-w-md hover:bg-[#3F3A64]'>Send Message</button>
          </form>
        </div>
      </div>

      {/* <div className='grid grid-cols-1 md:grid-cols-2 my-36 gap-8'>
          <div className='flex flex-col justify-center pl-4 md:pl-52'>
            <h1 className='text-4xl md:text-6xl font-bold text-[#3F3A64] text-center'>We're Here to Assist You!</h1>
            <h2 className='text-2xl md:text-4xl font-semibold text-[#3F3A64] text-center'>Reach Out Anytime!</h2>

            <div className='pt-9 space-y-5 text-center'>
              <div>
                <span className='text-[#20AD96] font-semibold'>Get in Touch:</span>
                <div className='flex flex-col items-center space-y-4'>
                  <div className='flex items-center space-x-2'>
                    <LocalPhoneIcon style={{ fontSize: 30, color: "#3F3A64" }} />
                    <h1 className='text-xl md:text-3xl font-bold text-[#3F3A64]'>+123 456 789 000</h1>
                  </div>
                  <div className='flex items-center space-x-2'>
                    <WhatsAppIcon style={{ fontSize: 30, color: "#3F3A64" }} />
                    <h1 className='text-xl md:text-3xl font-bold text-[#3F3A64]'>+123 456 789 001</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        
        </div> */}

      <Footer />
    </>
  )
}


{/* Removed the image section */ }
{/* <div className='mb-20 flex justify-center'>
            <img className='rounded-[50px] w-full max-h-390' style={{ maxHeight: 390 }} src={customer} alt="Customer Support" />
          </div> */}
export default HomePage;