import React from 'react'

const Devices = () => {
    return (
        <div className=' grid grid-cols-1 lg:grid-cols-2 bg-white  rounded-[0px]  lg:rounded-[50px] my-28'>
            <div className=' py-12 px-3  lg:px-16  space-y-9'>
                <h1 className=' text-2xl lg:text-5xl text-[#3F3A64] font-bold lg:font-semi'>Order multiple education tabs at once</h1>
                <h1 className=' text-[]17px] lg:text-[18px] text-[#20AD96]'>Now you can equip your students with the best in-class technology to upgrade their to learning experience by ordering Education Tabs in bulk. Order a minimum quantity of 100 tabs all at once.</h1>
                <button className=' bg-[#3F3A64] text-white px-8 py-5 text-xl font-bold rounded-[40px]'>Request Bulk Order</button>
            </div>
            <div className=' bg-[] grid grid-cols-4'>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default Devices