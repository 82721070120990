import React from 'react';
import report from "../assets/ai1.jfif";
import apstore from "../assets/iphone2.png";
import playstore from "../assets/playstore.png";
import windows from "../assets/windows.png";
import { isMobile } from 'react-device-detect';
import browser from "../assets/b_main.png"
import black from "../assets/black.jpg";
import student1 from "../assets/student1.jfif";
import ps from "../assets/ps.png";
import as from "../assets/ap.png";
import ob from "../assets/ob.png";

const DownloadOnline = ({ targetRef }) => {
    // style={{width:"100%", maxWidth:320, }}
    return (
        <div ref={targetRef} className=' rounded-[0px] lg:rounded-[50px] bg-[#20AD96] pb-12 lg:mb-36'>
            <div>
                <div className=' space-y-3 py-12 lg:py-28'>
                    <h1 className=' text-2xl lg:text-5xl font-bold text-white text-center'>Download the learning app </h1>
                    <h1 className='  text-3xl lg:text-5xl font-bold text-white  text-center'>for better <span className=' text-[#3F3A64] font-bold text-3xl lg:text-5xl'>school grades</span></h1>
                    <div className=' grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-3 px-2 lg:px-8 gap-5 lg:pt-10 pt-3'>
                        <div>
                            <img src={ps} className='w-full  h-auto' />
                        </div>
                        <div>
                            <img src={as} className='w-full  h-auto' />
                        </div>
                        {isMobile ? null :
                            <div onClick={() => {
                                window.open("https://app.smartlearnersonline.com/");
                            }}>
                                <img src={ob} className='w-full  h-auto' />
                            </div>
                        }
                    </div>

                </div>
                {isMobile ?
                    <div className=' grid place-items-center mb-8  lg:mb-0'>
                        <img src={ob} className=' w-[50%]' />
                    </div>
                    : null}

            </div>
            <div className=' grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 px-4 lg:px-8 lg:py-11'>
                <img src={report} className=' rounded-full h-48 w-48 sm:h-72 sm:w-72' />
                <img src={student1} className=' rounded-full h-48 w-48 sm:h-72 sm:w-72' />
                {isMobile ? null :
                    <>

                        <img src={black} className=' rounded-full h-48 w-48 sm:h-72 sm:w-72' />
                        <img src={report} className=' rounded-full h-48 w-48 sm:h-72 sm:w-72' />
                    </>
                }
            </div>

        </div>
    )
}

export default DownloadOnline