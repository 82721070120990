import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import smllogo from "../../assets/smllogo.png";

const MobileHeader = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className='flex items-center justify-between px-4 lg:hidden sticky z-10 top-0 bg-white shadow-md'>
            <div>
                <img className='rounded-lg w-full max-w-[150px] sm:max-w-[90px] sm:max-h-[90px]' src={smllogo} alt="Logo" />
            </div>
            {/* <div>
                <IconButton onClick={toggleSidebar}>
                    <MenuIcon style={{ fontSize: 30 }} />
                </IconButton>
            </div> */}

        </div>
    )
}

export default MobileHeader;