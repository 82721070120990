import React from 'react';
// import student_image from "../assets/african_student4.jfif";
import student_image from "../assets/africant_student2.jfif";
// africant_student2

const StudentImage = () => {
    return (
        <div style={{
            backgroundImage: `linear-gradient(to bottom, rgba(14, 36, 74, 0.8), rgba(14, 36, 74, 0.4)), url(${student_image})`,
            backgroundSize: "cover",
            height: 700
        }} className=' my-20 lg:my-36 lg:rounded-[40px] rounded-[0px]'>

            <div className=" grid place-items-center lg:h-screen  h-[85vh]">
                <div className=' lg:w-[50%] w-[90%] md:w-[70%] pt-10 md:pt-20 space-y-8'>
                    <h1 className=' text-[#EA7052] text-4xl md:text-5xl font-bold text-center'>
                        Government + uLesson = Societal Transformation
                    </h1>
                    <h1 className=' text-[16px] md:text-[18px] text-center text-white'>Are you interested in making a change with uLesson? uLesson partner with government bodies to foster initiatives that contribute to societal impact through education.</h1>
                    <div className='grid place-items-center'>
                        <button className=' px-10 md:px-16 bg-[#EA7052] py-4 md:py-6 text-white text-lg md:text-xl font-extrabold rounded-[30px]'>Contact Us</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StudentImage

{/* <img className='rounded-[40px]' src={student_image} style={{ width: "100%", height:900 }} /> */ }


// 