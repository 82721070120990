import React from 'react'
import ClassCards from './ClassCards'
import image1 from "../../src/assets/image1.jpg";
import image2 from "../../src/assets/image3.jpg";
import image3 from "../assets/image2.jpg";

const Forms = () => {
    const Data = [
        {
            image: image1,
            title: "Learn Anywhere and Anytime",
            description: "Access quality education anytime, anywhere, with our mobile-friendly learning solutions."
        },
        {
            image: image2,
            title: "1000+ Learning Resources",
            description: "Explore a vast library of educational materials to fuel your growth."
        },
        {
            image: image3,
            title: "Mentoring by certified Teachers",
            description: "Achieve academic excellence with expert support and mentorship."
        }
    ]
    return (
        <div>
            <div className='py-16 grid place-items-center lg:px-0 px-2'>
                <div className=' w-full lg:w-[55%] space-y-7'>
                    <h1 className=' text-center  text-3xl lg:text-5xl  text-[#3F3A64] font-bold'>No one is left behind!</h1>
                    <p className=' lg:text-[18px] text-[16px]  text-center  text-[#20AD96]'>Our lessons are designed to match the needs of students across various grades, including Primary, Junior Secondary, and Senior Secondary levels.</p>
                </div>
            </div>
            <div className=' grid lg:grid-cols-3  grid-cols-1 gap-7 px-3'>
                {
                    Data.map((list) => {
                        return (
                            <ClassCards image={list.image} description={list.description} title={list.title} />
                        );
                    })
                }

                {/* <ClassCards />
                <ClassCards /> */}
            </div>
        </div>
    )
}

export default Forms