import React, { useRef } from 'react';
import smllogo from "../assets/smllogo.png";
import { Divider } from '@mui/material';

const Header = ({ targetRef }) => {
    const scrollToTarget = () => {
        targetRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className=' bg-[#F2F2FB] sticky top-0 z-50  lg:block hidden'>
            <div className='flex items-center justify-between px-28 py-2'>
                <div>
                    <img className=' rounded-[4px]' src={smllogo} style={{ width: "100%", maxWidth: 159, maxHeight: 159 }} />
                </div>
                <div className=' flex items-center space-x-8'>
                    <div className=' flex items-center space-x-4'>
                        <svg color='#0072A9' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" class="w-6 h-6">
                            <path fill-rule="evenodd" d="M15 3.75a.75.75 0 0 1 .75-.75h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V5.56l-4.72 4.72a.75.75 0 1 1-1.06-1.06l4.72-4.72h-2.69a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" />
                            <path fill-rule="evenodd" d="M1.5 4.5a3 3 0 0 1 3-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 0 1-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 0 0 6.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 0 1 1.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 0 1-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5Z" clip-rule="evenodd" />
                        </svg>
                        <h1 className=' text-[#0072A9] font-semibold text-lg'>+233 577379731</h1>
                    </div>
                    <button
                        className=' px-14 py-3 rounded-[23px] text-[white] font-semibold bg-[#20AD96] border-2 border-[#20AD96]'
                        onClick={scrollToTarget}
                    >
                        Get Started
                    </button>
                </div>
            </div>

            {/* <Divider /> */}
        </div>
    )
}

export { Header };