import React, { useRef } from 'react';
import report from "../assets/report2.png";
import { useNavigate } from 'react-router-dom';

const Banner = () => {

  const navigate = useNavigate();

  const targetRef = useRef(null);

  const scrollToTarget = () => {
    targetRef.current?.scrollIntoView({ behavior: 'smooth' });
  };
  // bg-[#3F3A64]
  return (
    <div className=' grid grid-cols-1 sm:grid-cols-2 bg-[#F2F2FB] px-4 sm:px-8 lg:px-44 pb-12 lg:pb-36'>
      <div className=' py-14 sm:pt-28 animate__animated animate__backInLeft'>
        <div className=' space-y-4 sm:space-y-6 text-center'>
          <h1 className=' text-4xl sm:text-3xl lg:text-7xl font-bold text-[#3F3A64]  text-center lg:text-start'><span className=' text-4xl sm:text-3xl lg:text-7xl font-bold text-[white] bg-[#FF6030] px-3 rounded-[5px] mr-1 shadow-2xl'>B</span>ack to School !</h1>
          <h1 className=' text-2xl sm:text-3xl lg:text-6xl font-bold text-[#20AD96] text-center lg:text-start'>with <span className='text-4xl sm:text-6xl font-bold text-[#20AD96]'>Smart</span> Learners!</h1>
          <h1 className=' text-[18px] sm:text-[22px] text-[#6A567C] font-bold text-center lg:text-start'>Learners on the smartlearners are equipped for success! Get your child on board Now!</h1>
          <div className=' flex flex-col sm:flex-row items-center space-x-0 sm:space-x-4 space-y-5 lg:space-y-0'>
            <button  onClick={() => {
              window.open("https://app.smartlearnersonline.com/");
            }} className=' w-full sm:w-auto bg-[#20AD96] text-white px-8 sm:px-16 py-3 sm:py-5 text-lg sm:text-xl font-bold rounded-[40px] shadow-xl'>Get Started Now!</button>
            {/* <button className=' w-full sm:w-auto border-2 border-[#20AD96] text-[#20AD96] px-8 sm:px-16 py-3 sm:py-5 text-lg sm:text-xl font-bold rounded-[40px] shadow-xl'>Login</button> */}
          </div>
        </div>
      </div>
      <div className=' animate__animated animate__fadeInBottomLeft'>
        <img src={report} className='w-full h-auto' />
      </div>
    </div>
  )
}

export default Banner;

// #20AD96