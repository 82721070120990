import React from 'react';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';

const QuizCards = () => {
    return (
        <div className=' bg-white shadow-md px-4 py-4 rounded-lg'>
            <img className=' rounded-[11px]' src='https://ulesson.com/images/png/secondary.png' style={{ width: "100%", Maxheight: 200, maxWidth: "100%" }} />
            <div className=' flex items-center justify-between mt-5'>
                <div>
                    <h1 className=' text-lg font-bold text-[#3F3A64]'>Maths</h1>
                    <h1 className=' text-[17px] text-[#20AD96]'>The Velocity Time Graph</h1>
                </div>
                <div className=' mt-[-60px]'>
                    <PlayCircleIcon style={{ fontSize: 70, color: "#20AD96" }} />
                </div>
            </div>
        </div>
    )
}

export default QuizCards;