import React from 'react';
import new_student from "../assets/new_student.jfif";

const LiveLesson = () => {
    return (
        <div className=' grid grid-cols-1 lg:grid-cols-2 gap-32 my-24'>
            <div>
                <img className=' rounded-[50px]' src={new_student} />
            </div>

            <div className=' space-y-6 pt-20'>
                <h1 className=' text-5xl font-bold text-[#3F3A64]'>Refer a <span className='text-5xl font-bold text-[#3F3A64]'>Learner</span> and Earn </h1>
                <h1 className=' text-5xl font-bold text-[#3F3A64]'>up to <span className='text-5xl font-bold text-[#3F3A64]'>₦10,000</span></h1>
                <h1 className=' text-[18px] text-[#20AD96]'>Refer students in primary and secondary school to the uLesson app and earn up to ₦10,000 for every subscriber!</h1>
                <button className=' bg-[#3F3A64] text-white px-8 py-5 text-xl font-bold rounded-[40px]'>Learn More</button>
            </div>

        </div>
    )
}

export default LiveLesson;

