import React from 'react';
import smllogo from "../assets/smllogo.png";
import { Divider } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';


const Footer = () => {
    return (
        <div className='px-3 lg:px-28 pb-10'>
            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-5 lg:gap-36'>
                <div>
                    <img src={smllogo} className='' style={{ width: "100%", maxWidth: 142, maxHeight: 142 }} />
                    <div>
                        <p>Smart Leaners leverages the best in-class teachers, media, and technology to create high-quality,</p>
                    </div>
                </div>
                <div className='flex flex-col items-start'>
                    <h1 className='font-bold text-lg text-[#3F3A64]'>Socials</h1>
                    <div className='pt-5 space-y-2'>
                        <div>
                            <p className=' text-[#20AD96]'>LinkedIn</p>
                        </div>

                        <div>
                            <p className=' text-[#20AD96]'>Youtube</p>
                        </div>

                        <div>
                            <p className=' text-[#20AD96]'> Twitter</p>
                        </div>

                        <div>
                            <p className=' text-[#20AD96]'>Facebook</p>
                        </div>
                        <div>
                            <p className=' text-[#20AD96]'>Instagram</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className='font-bold text-lg text-[#3F3A64]'>Resources</h1>
                    <div className='pt-5 space-y-5'>
                        <div>
                            <p className='text-[#20AD96]'>Blog</p>
                        </div>
                        <div>
                            <p className='text-[#20AD96]'>Webinars</p>
                        </div>
                        <div>
                            <p className='text-[#20AD96]'>E-books</p>
                        </div>
                    </div>
                </div>
                <div>
                    <h1 className='font-bold text-lg text-[#3F3A64]'>Contact Us</h1>
                    <div className='pt-5 space-y-5'>
                        <div>
                            <p className='text-[#20AD96]'>Email: support@ulesson.com</p>
                        </div>
                        <div>
                            <p className='text-[#20AD96]'>Phone: +123 456 7890</p>
                        </div>
                    </div>
                </div>


            </div>
            <div className='py-6'>
                <Divider />
            </div>
            <div className='flex flex-col sm:flex-row items-center justify-between'>
                <div className='flex items-center space-x-4'>
                    <FacebookIcon />
                    <TwitterIcon />
                    <YouTubeIcon />
                    <InstagramIcon />
                </div>
                <div className='flex flex-col sm:flex-row items-center space-x-0 sm:space-x-5'>
                    <p className='py-2 sm:py-0'>Privacy Policy</p>
                    <p className='py-2 sm:py-0'>Terms of Service</p>
                    <p className='py-2 sm:py-0'>Help/FAQ</p>
                </div>
            </div>
        </div>
    )
}

export default Footer;