import React from 'react'
import StudentsCommentsCards from './StudentsCommentsCards'

const StudentCommentContainer = () => {
    return (
        <div>
            <div className=' grid place-items-center' >
                <div className=' w-[100%] lg:w-[55%]'>
                    <h1 className=' text-center text-3xl  lg:text-5xl font-bold py-10 lg:py-20 text-[#3F3A64]'>Don't take our word for it. Hear from our learners</h1>
                </div>
            </div>
            <div className=' grid grid-cols-1 lg:grid-cols-4 gap-4 px-3'>
                <StudentsCommentsCards />
                <StudentsCommentsCards />
                <StudentsCommentsCards />
                <StudentsCommentsCards />
            </div>
        </div>
    )
}

export default StudentCommentContainer;